<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card card-plain">
          <tabs centered square>
            <tab-pane>
              <template slot="label">
                <div class="d-flex align-items-center">
                  <div class="text-uppercase m-3">{{ $t('common.details') }}</div>
                </div>
              </template>
              <octo-header-button :title="course.title" :buttons="courseButtons" @onSaveCourse="saveCourse"/>
              <course-form-card
                :course="course"
                :title="$t('course.workshop_data')"
                :key="`form-${courseKey}`"
                ref="courseForm"
              />

            </tab-pane>

            <tab-pane>
              <template slot="label">
                <div class="d-flex align-items-center">
                  <div class="text-uppercase m-3">{{ $t('common.courses') }}</div>
                </div>
              </template>
              <octo-header-button :title="course.title" :buttons="coursesButtons" @onSyncCourses="syncCourses"/>
              <certificate-courses-card
                :course="course"
                :key="`certificates-${courseKey}`"
                ref="certificateCoursesCard"
              />
            </tab-pane>

            <tab-pane>
              <template slot="label">
                <div class="d-flex align-items-center">
                  <div class="text-uppercase m-3">{{ $t('fields.tutors') }}</div>
                </div>
              </template>
              <octo-header-button :title="course.title" :buttons="tutorButtons" @onSyncTutors="syncTutors"/>
              <course-tutors-card :course="course" :key="`tutor-selector-${courseKey}`" ref="courseTutors"/>
            </tab-pane>

            <tab-pane>
              <template slot="label">
                <div class="d-flex align-items-center">
                  <div class="text-uppercase m-3">{{ $t('page.selected_course_modules') }}</div>
                </div>
              </template>
              <octo-header-button
                :title="course.title"
                :buttons="moduleButtons"
                @onSyncCourseModules="syncCourseModules"
              />
              <card class="shadow" body-classes="standard-card-body pt-0">
                <div slot="header">
                  <h4 class="card-title text-capitalize">{{ $t('common.course_modules') }}</h4>
                </div>
                <course-module-picker
                  :key="`module-picker-${courseKey}`"
                  :selected-course-modules="course.courseModules"
                  ref="courseModulePicker"
                />
              </card>
            </tab-pane>
          </tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {endpoints} from "@/routes/endpoints";
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import Course from "@/models/course";
import {mapMutations} from "vuex";
import {TabPane, Tabs} from '@/components';
import OctoIcon from "@/components/octo-icon/OctoIcon";
import CourseFormCard from "./components/CourseFormCard";
import CourseTutorsCard from "./components/CourseTutorsCard";
import CertificateCoursesCard from "./components/CertificateCoursesCard";
import CourseModulePicker from "../Didactics/components/CourseModulePicker";

export default {
  name: "ShowWorkshopPage",
  components: {
    CourseModulePicker,
    CertificateCoursesCard,
    CourseTutorsCard,
    CourseFormCard,
    OctoIcon,
    OctoHeaderButton,
    TabPane,
    Tabs
  },
  data() {
    return {
      endpoints: endpoints,
      course: this.$_.cloneDeep(Course),
      courseKey: 1,
      courseButtons: [
        {label: 'save', onClick: 'onSaveCourse'}
      ],
      moduleButtons: [
        {label: 'save_course_modules', onClick: 'onSyncCourseModules'}
      ],
      tutorButtons: [
        {label: 'save_tutors', onClick: 'onSyncTutors'}
      ],
      coursesButtons: [
        {label: 'save_courses', onClick: 'onSyncCourses'}
      ],
    }
  },
  beforeMount() {
    this.$fullLoading.show();
    this.$api.get(endpoints.COURSE_SHOW.replace('{id}', this.$route.params.id))
      .then((resp) => {
        this.course = resp.data.data;
        this.courseKey++;
        this.$fullLoading.hide();
      })
      .catch(() => {
        this.$fullLoading.hide();
        this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
        this.$router.push({name: 'courses.workshops.index'})
      })
  },
  methods: {
    ...mapMutations({
      updateCourses: 'common/updateCourses'
    }),

    saveCourse: async function () {
      try {
        this.$fullLoading.show();
        const courseData = await this.$refs.courseForm.validate();
        courseData.courseIds = this.$refs.certificateCoursesCard.getSelectedCourseIds();

        const resp = await this.$api.put(
          endpoints.COURSE_UPDATE.replace('{id}', courseData.id),
          courseData
        );

        this.updateCourses(resp?.data?.data);
        this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
      } catch (e) {
        this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
      } finally {
        this.$fullLoading.hide();
      }
    },

    syncCourseModules() {
      this.$fullLoading.show();

      this.$api.put(
        endpoints.COURSE_SYNC_COURSE_MODULES.replace('{id}', this.course.id),
        {courseModules: this.$refs.courseModulePicker.getCourseModules()}
      )
        .then((resp) => {
          this.updateCourses(resp?.data?.data);
          this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
        })
        .finally(() => {
          this.$fullLoading.hide();
        });
    },

    syncTutors() {
      this.$fullLoading.show();

      this.$api.put(
        endpoints.COURSE_SYNC_TUTORS.replace('{id}', this.course.id),
        {tutorIds: this.$refs.courseTutors.getSelectedTutorIds()}
      )
        .then((resp) => {
          this.updateCourses(resp?.data?.data);
          this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
        })
        .finally(() => {
          this.$fullLoading.hide();
        });
    },

    syncCourses: async function () {
      try {
        this.$fullLoading.show();

        const resp = await this.$api.put(
          endpoints.COURSE_SYNC_COURSES.replace('{id}', this.course.id),
          {courseIds: this.$refs.certificateCoursesCard.getSelectedCourseIds()}
        );

        this.updateCourses(resp?.data?.data);

        this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
      } catch (e) {
        this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
      } finally {
        this.$fullLoading.hide();
      }
    },
  }
}
</script>

<style scoped>

</style>
