<template>
  <card body-classes="standard-card-body">
    <div slot="header">
      <h4 class="card-title text-capitalize">{{ $t('fields.courses') }}</h4>
    </div>
    <list-selector-component :number-items-per-page="numberItemsPerPage" :list="coursesLocal" ref="courseSelector">
      <template v-slot:default="slotProps">
        <div>
          <div class="standard-label text-truncate">
            <label-theme-component>{{ allCourses[slotProps.item.id] | optional('code') }}</label-theme-component>
            {{ allCourses[slotProps.item.id] | optional('title') }}
          </div>
        </div>
      </template>
    </list-selector-component>
  </card>
</template>

<script>
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import {Option, Select} from "element-ui";
import Course from "@/models/course";
import {mapGetters} from "vuex";
import NoDataComponent from "@/components/NoDataComponent";
import ListSelectorComponent from "@/components/ListSelectorComponent";
import LabelThemeComponent from "@/components/LabelThemeComponent";

export default {
  name: "CertificateCoursesCard",
  components: {
    LabelThemeComponent,
    ListSelectorComponent,
    NoDataComponent,
    ListGroupItemComponent,
    [Select.name]: Select,
    [Option.name]: Option
  },
  data() {
    return {
      coursesLocal: []
    }
  },
  mounted() {
    this.$_.each(this.$_.cloneDeep(this.courses), (item) => {
      item.selected = !!this.$_.find(this.course.courses, tutor => item.id === tutor.id);
      this.coursesLocal.push(item);
    });
  },
  props: {
    course: {
      type: Object,
      default: () => _.cloneDeep(Course)
    },
    numberItemsPerPage: {
      type: Number,
      default: 12
    }
  },
  computed: {
    ...mapGetters({
      courses: 'common/courses',
      allCourses: 'common/allCourses',
    }),
  },
  methods: {
    getSelectedCourseIds() {
      return this.$_.map(this.$refs.courseSelector.getSelectedItems(), course => course.id);
    },

    getSelectedCourses() {
      return this.$refs.courseSelector.getSelectedItems();
    }
  }
}
</script>

<style scoped>

</style>
